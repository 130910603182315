import React, { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { useSSR } from 'react-i18next';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import { configureStore } from './store';
import routes from './routes';
import theme from './theme';
import { ThemeProvider } from '@mui/styles';
import 'moment-timezone';
import './i18n';

const preloadedState = getSerializedData('preloaded_state');
// Configurate Redux store
const { store, history } = configureStore({ initialState: preloadedState });

const initialI18nStore = getSerializedData('preloaded_i18n');
const initialLanguage = getSerializedData('preloaded_language');

// TODO remove ServerAppState window.__SERVER_APP_STATE__

function Main({ data }) {
  React.useEffect(() => {
    // const jssStyles = document.querySelector('#jss-server-side');
    // if (jssStyles) {
    //   jssStyles.parentElement.removeChild(jssStyles);
    // }
    const jssStyles = document.getElementById('jss-ssr');
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);
  }, []);
  useSSR(initialI18nStore, initialLanguage);
  return (
    <Suspense fallback={<div>Still loading i18n...</div>}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <After data={data} routes={routes} />
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </Suspense>
  );
}

ensureReady(routes).then((data) =>
  hydrate(<Main data={data} />, document.getElementById('root'))
);

if (module.hot) {
  module.hot.accept();
}
