import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
// import asyncExample from './modules/asyncExample/reducers';

// Reducers configuration
const rootReducer = (history) => combineReducers({
    router: connectRouter(history)
    // asyncExample,
});

export default rootReducer;
