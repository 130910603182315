import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';
import NotFoundPage from './components/notFound';

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Home'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(en|lv|ru)/about',
    exact: true,
    component: asyncComponent({
      loader: () => import('./containers/about'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(en|lv|ru)/privacypolicy',
    exact: true,
    component: asyncComponent({
      loader: () => import('./containers/privacyPolicy'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(en|lv|ru)/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Home'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(en|lv|ru)/:wtype(w|table)/:place',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Weather'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(en|ru)/:wtype(w|table)/:place/:day(2day|3day|4day|5day)',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Weather'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(lv)/:wtype(w|table)/:place/:day(2diena|3diena|4diena|5diena)',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Weather'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(lv)/5-dienam',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Home'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(en)/5-days',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Home'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(ru)/5-дней',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Home'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/:lng(en|lv|ru)/all',
    exact: true,
    component: asyncComponent({
      loader: () => import('./AllList'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },

  // {
  //     path: '/about',
  //     exact: true,
  //     component: asyncComponent({
  //         loader: () => import('./About'), // required
  //         Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
  //     }),
  // },
  {
    component: NotFoundPage,
  },
];