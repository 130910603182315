import React from 'react';
import Error from '../error';
import { Route } from 'react-router-dom';

class NotFound extends React.Component {
  render() {
    return (
      <Route
        render={({ staticContext }) => {
          if (staticContext) staticContext.statusCode = 404;
          return (
            <Error
              buttonText="Back to homepage"
              header="404"
              row1="Page not found"
              row2="We are sorry but the page you are looking for does not exist."
            />
          );
        }}
      />
    );
  }
}

export default NotFound;
