import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
// import promiseMiddleware from 'redux-promise-middleware';
import { createBrowserHistory, createMemoryHistory } from 'history';
import rootReducer from './rootReducer';

/* eslint no-underscore-dangle: 0 */

// A nice helper to tell us if we're on the server
const isServer = !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

const configureStore = ({ initialState, url = '/', middleware = [] } = {}) => {
    const history = isServer
        ? createMemoryHistory({
            initialEntries: [url]
        })
        : createBrowserHistory();

    const devtools = typeof window !== 'undefined'
        && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
        && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsDenylist: [] });

    const composeEnhancers = (process.env.NODE_ENV !== 'production' && devtools) || compose;

    middleware.push(routerMiddleware(history)); // for dispatching history actions
    // middleware.push(promiseMiddleware()); // async action

    const store = createStore(
        rootReducer(history),
        // connectRouter(history)(rootReducer),
        initialState,
        composeEnhancers(applyMiddleware(...[thunk].concat(...middleware))),
    );

    if (process.env.NODE_ENV !== 'production' && !isServer) {
        if (module.hot) {
            // console.debug(module.hot);
            // eslint-disable-next-line global-require
            module.hot.accept('./rootReducer', () => store.replaceReducer(require('./rootReducer').default(history)));
        }
    }

    return { store, history };
};

export { configureStore, isServer };
