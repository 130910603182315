import { createTheme } from '@mui/material/styles';
import { indigo, orange, blue } from '@mui/material/colors';

// Configure Material UI theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#2979ff',
    },
    accent: orange,
    type: 'light',
  },
});

export default theme;
